import logo from './awaacc.png';
import './App.css';
import Header from './components/Header';
import Logos from './components/Logos';

function App() {
  return (
    <div className="App">
      <Header/>
        
        <Logos/>
  
    </div>
  );
}

export default App;