import '../App.css';
import FiveNineLogo from '../FiveNineLogo.svg';

function Logos() {
  return (
    <div className="header">
        <img src={FiveNineLogo} className="FiveNineLogo"/>
        <ul>
            <li>
                <a href="mailto:mike@fiveninevc.com">Contact</a>
            </li>
        </ul>
    </div>
  );
}

export default Logos;
