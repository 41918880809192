import '../App.css';
import { useState } from "react";
import { HorizontalTicker } from "react-infinite-ticker";
import Athelas from '../complogos/athelas.png';
import Anduril from '../complogos/anduril.png';
import MongoDB from '../complogos/mongodb.png';
import Slack from '../complogos/slack.png';
import Pinterest from '../complogos/pinterest.png';
import Wish from '../complogos/wish.png';
import Palantir from '../complogos/palantir.png';
import Klarna from '../complogos/klarna.png';
import CloudNine from '../complogos/cloud9.png';
import Planet from '../complogos/planet.png';
import Umbra from '../complogos/umbra.png';
import Coupang from '../complogos/coupang.png';
import ECL from '../complogos/ecl.png';
import Superside from '../complogos/superside.png';
import RemoteImg from '../remote.png';
import logo from '../awaacc.png';








function Header() {
  
function changeVid(buttonlink) { 
  document.getElementById('TV').src = buttonlink;
  console.log('Success! Changed to ' + document.getElementById('TV').src);
 
}

  const [videoState, setVideoState] = useState(0);
  const [sponsorState, setSponsorState] = useState(0);
  function switchVid() {
    setVideoState(videoState ? 0 : 1);
    console.log(videoState)
  }
  const Sponsor = ["Athelas", "Anduril", "MongoDB",
   "Slack","Pinterest", "Wish",
    "Palantir", "Klarna", "CloudNine",
     "Planet", "Umbra", "Coupang",
      "ECL", "Superside"];
      
  const isBefore = videoState;
  const Source = {
    Athelas: {0:'https://www.youtube.com/embed/0J_XspPlJSY', 1: 'https://www.youtube.com/embed/DddkjMyyJC0'},
    Anduril: {0:'https://www.youtube.com/embed/kpw4JmIHb-I', 1: 'https://www.youtube.com/embed/VJ0IHWqlAz4'},
    MongoDB: {0:'https://www.youtube.com/embed/NyLDLLpUj60', 1: 'https://sdtimes.com/wp-content/uploads/2017/10/Screen-Shot-2017-10-19-at-2.33.03-PM.png'},
    Slack: {0:'https://www.youtube.com/embed/dDOaam8XI8c', 1: 'https://www.youtube.com/embed/PF8PW_wKZ2s'},
    Pinterest: {0:'https://www.youtube.com/embed/KYQHPHYs2Os', 1: 'https://www.youtube.com/embed/nHElGILAZ-c'},
    Wish: {0:'https://www.youtube.com/embed/M9Ff7zZptYU', 1: 'https://www.presse-citron.net/app/uploads/2020/12/wish-introduction-bourse.jpg'},
    Palantir: {0:'https://www.youtube.com/embed/VJFk8oGTEs4', 1: 'https://www.youtube.com/embed/GQO7qM7ojHc?start=391'},
    Klarna: {0:'https://www.youtube.com/embed/sSe11xiIMRM', 1: 'https://www.youtube.com/embed/5pACFgOnh6U'},
    CloudNine: {0:'https://www.youtube.com/embed/DnHh6V7_v9o', 1: 'https://www.youtube.com/embed/dA6EP_RdwhU'},
    Planet: {0:'https://www.youtube.com/embed/Affs5XQkLmY?start=110', 1: 'https://www.youtube.com/embed/BLscGsjoPoI'},
    Umbra: {0:'https://www.youtube.com/embed/gqYzoaO3Bco?start=1207', 1: 'https://www.youtube.com/embed/yqWX86uT5jM'},
    Coupang: {0:'https://www.youtube.com/embed/DX4lLvpEd-8?start=98', 1: 'https://www.youtube.com/embed/TRAKBSZB0PY'},
    ECL: {0:'https://www.youtube.com/embed/OVmrRUtjssc', 1: 'https://www.youtube.com/embed/j8bvZZzYGiw?start=387'},
    Superside: {0:'https://www.youtube.com/embed/C0WMdO-SBp8?start=224', 1: 'https://www.youtube.com/embed/yqWX86uT5jM'},
  }

  function switchSponsor() {
    let state = sponsorState + 1;
    state = state % this.length;

    setSponsorState(state);
  }
  return (
    <div className="Logos">
      <span>{isBefore ? 'OBVIOUS TO INSIDERS' : 'NOT OBVIOUS TO OUTSIDERS'}</span>
      <img src={logo} className="App-logo" alt="logo" />
      
     
      <iframe id="TV" className="logo-vid" src={Source.Athelas[videoState]} title="Video Player" autoplay="on"allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      
      <div className="remote-cnt">
      {isBefore ?  <span>NOW</span>: <span></span>}
      
        <button onClick={switchVid}><img src={RemoteImg} className="remote-img"/></button>
        {isBefore ? <span></span> : <span>THEN</span>}  

      </div>
      
       
        <br/> <br/>
        <div className="logos-cnt">
        <HorizontalTicker classname="horizontal-ticker" duration={35000}  easing="linear">
          <div className="logo-btn">
            <button onClick={() => changeVid(Source.Athelas[videoState])}> <img src={Athelas} className="logo-button" alt="logo" /></button>
          </div>

          <div className="logo-btn">
            <button onClick={() => changeVid(Source.Anduril[videoState])}> <img src={Anduril} className="logo-button" alt="logo" /></button>
          </div>

          <div className="logo-btn disabled">
            <button onClick={() => changeVid(Source.MongoDB[videoState])}><img src={MongoDB} className="logo-button" alt="logo" /></button>
          </div>

          <div className="logo-btn disabled">
            <button onClick={() => changeVid(Source.Slack[videoState])}><img src={Slack} className="logo-button" alt="logo" /></button>
          </div>

          <div className="logo-btn disabled">
            <button onClick={() => changeVid(Source.Pinterest[videoState])}><img src={Pinterest} className="logo-button" alt="logo" /></button>
          </div>

          <div className="logo-btn disabled">
            <button onClick={() => changeVid(Source.Wish[videoState])}><img src={Wish} className="logo-button" alt="logo" /></button>
          </div>

          <div className="logo-btn disabled">
            <button onClick={() => changeVid(Source.Palantir[videoState])}><img src={Palantir} className="logo-button" alt="logo" /></button>
          </div>

          <div className="logo-btn disabled">
            <button onClick={() => changeVid(Source.Klarna[videoState])}><img src={Klarna} className="logo-button" alt="logo" /></button>
          </div>

          <div className="logo-btn disabled">
            <button onClick={() => changeVid(Source.CloudNine[videoState])}><img src={CloudNine} className="logo-button" alt="logo" /></button>
          </div>

          <div className="logo-btn disabled">
            <button onClick={() => changeVid(Source.Planet[videoState])}><img src={Planet} className="logo-button" alt="logo" /></button>
          </div>

          <div className="logo-btn disabled">
            <button onClick={() => changeVid(Source.Umbra[videoState])}><img src={Umbra} className="logo-button" alt="logo" /></button>
          </div>

          <div className="logo-btn disabled">
            <button onClick={() => changeVid(Source.Coupang[videoState])}><img src={Coupang} className="logo-button" alt="logo" /></button>
          </div>

          <div className="logo-btn disabled">
            <button onClick={() => changeVid(Source.ECL[videoState])}><img src={ECL} className="logo-button" alt="logo" /></button>
          </div>

          <div className="logo-btn disabled">
            <button onClick={() => changeVid(Source.Superside[videoState])}><img src={Superside} className="logo-button" alt="logo" /></button>
          </div>

          </HorizontalTicker>
        </div>
       
    </div>
  );
}

export default Header;
